<template>
  <div>
    <KTCodePreview v-bind:title="'In phiếu'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In phiếu
        </b-button>
      </template>
      <template v-slot:preview>
        <div id="printMe">
          <b-row style="display: block" class="mb-10">
            <div class="text-center">
              <h5>{{ stockSlip.typeName }}</h5>
              <p>{{ stockSlip.modeName }}</p>
            </div>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Mã phiếu</span>
                : {{ stockSlip.code }}
              </p>
            </b-col>
            <b-col md="4">
              <p>
                <span class="custom-lable-text">Kho</span>
                : {{ stockSlip.storeName }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Ngày</span>
                : {{ stockSlip.createdAt }}
              </p>
            </b-col>
            <b-col md="4">
              <p>
                <span class="custom-lable-text">Địa chỉ</span>
                : {{ stockSlip.storeAddress }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">{{
                  stockSlip.providerLableName
                }}</span>
                : {{ stockSlip.providerName }}
              </p>
            </b-col>
            <b-col md="4">
              <p>
                <span class="custom-lable-text">Người lập</span>
                : {{ stockSlip.createBy }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Điện thoại</span>
                : {{ stockSlip.providerPhoneNo }}
              </p>
            </b-col>
            <b-col md="4">
              <p v-if="stockSlip.billId">
                <span class="custom-lable-text">Từ hóa đơn</span>
                : {{ stockSlip.billId }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Ghi chú</span>
                : {{ stockSlip.description }}
              </p>
            </b-col>
          </b-row>
          <b-table
            :items="listItem"
            :fields="fields"
            :bordered="true"
            :hover="true"
          >
            <template slot="bottom-row">
              <td
                v-bind:colspan="5"
                class="text-right font-weight-bolder"
                style="font-weight: 600,color: '#181c32'"
              >
                <span>Tổng:</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumQuantity) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumUnitPrice) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumDiscountAmount) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumTotalAmount) }}</span>
              </td>
            </template>
          </b-table>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text"
                  >Tổng số tiền (viết bằng chữ)</span
                >
                : {{ stockSlip.textAmount }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <p class="mb-0">
                <span class="custom-lable-text">Người lập phiếu</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <span class="custom-lable-text">Người giao hàng</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <span class="custom-lable-text">Người nhận hàng</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col
              ><p class="mb-0">
                <span class="custom-lable-text">Thủ kho</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col
              ><p class="mb-0">
                <span class="custom-lable-text">Giám đốc</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import _ from 'lodash';
import { convertNumberToText, printData } from '../../../utils/common';

export default {
  data() {
    return {
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '17%' },
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'unitPrice',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
      ],
      listItem: [],
      stockSlip: {
        code: '',
        typeName: '',
        modeName: '',
        storeName: '',
        createdAt: '',
        storeAddress: '',
        providerName: '',
        providerPhoneNo: '',
        providerLableName: '',
        createBy: '',
        description: '',
        textAmount: '',
        billId: '',
      },
      sumQuantity: 0,
      sumUnitPrice: 0,
      sumDiscountAmount: 0,
      sumTotalAmount: 0,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'In phiếu kho' }]);
    this.getStockSlipById();
  },
  methods: {
    printData() {
      printData();
    },
    formatMoney: function (number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getStockSlipById: async function () {
      const stockSlipId = this.$route.query.id;
      await ApiService.get(
        `stock-slips/print-import-export-stockslips/${stockSlipId}`
      ).then((data) => {
        const responseData = data.data.data;
        responseData.stockSlipDetails.map((element, index) => {
          const item = {
            count: index + 1,
            ...element,
          };
          this.listItem.push(item);
        });
        this.stockSlip = responseData;
        this.sumQuantity = _.sumBy(this.listItem, 'quantity');
        this.sumUnitPrice = _.sumBy(this.listItem, 'unitPrice');
        this.sumDiscountAmount = _.sumBy(this.listItem, 'discountAmount');
        this.sumTotalAmount = _.sumBy(this.listItem, 'totalAmount');
        this.stockSlip.textAmount = convertNumberToText(this.sumTotalAmount);
      });
      printData();
    },
  },
};
</script>

<style scoped>
.custom-lable-text {
  font-size: 13px;
  font-weight: 500 !important;
}
</style>

<style >
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
